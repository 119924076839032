<template>
  <div id='request-passwword-reset' ref="requestPasswordReset">
    <div class="container webshop-container">
      <div class="content-wrapper">
        <span class="logo" />

        <error-toast
          :isVisible="toast.isVisible"
          :type="toast.type"
          :text="toast.text"
          :color="toast.color"
        />

        <h1 class="title">{{ $t('pages.requestPassword.title') }}</h1>
        <p>{{ $t('pages.requestPassword.information') }}</p>
        <p>{{ $t('pages.requestPassword.informationSpam') }}</p>
        <div class="form-wrapper">
          <base-input
            ref="emailInput"
            :placeholderText="$t('pages.requestPassword.inputs.email')"
            :type="'email'"
            :hasError="emailInput.hasError"
            @input="handleEmailInput"
          />
          <base-button
            :text="$t('pages.requestPassword.buttons.send')"
            :isLoading="isBtnLoading"
            :isDisabled="isEmailSent"
            @clicked="handleBtnClick"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import scroll from '@/components/shared/mixins/scroll';

import BaseInput from '@/components/shared/elements/inputs/BaseInput';
import BaseButton from '@/components/shared/elements/buttons/ButtonBase';

export default {
  name: 'RequestPasswordResetScreen',
  mixins: [scroll],
  props: {},
  components: {
    BaseInput,
    BaseButton,
    'error-toast': () => import('@/components/shared/elements/toasts/ErrorToast'),
  },
  data: () => ({
    emailInput: {
      hasError: false,
      value: undefined,
    },
    isBtnLoading: false,
    toast: {
      type: 'error',
      isVisible: false,
      text: undefined,
    },
    isEmailSent: false,
  }),
  created() {},
  computed: {},
  methods: {
    ...mapActions({
      requestPasswordReset: 'users/requestPasswordReset',
    }),
    handleEmailInput(val) {
      this.emailInput.value = val;
    },
    validateInput() {
      if (this.emailInput.value !== undefined && this.emailInput.value.length !== 0) {
        this.emailInput.hasError = false;
        return true;
      }

      this.emailInput.hasError = true;
      return false;
    },
    handleBtnClick() {
      const validateResult = this.validateInput();

      if (validateResult) {
        this.isBtnLoading = true;

        this.requestPasswordReset({
          email: this.emailInput.value,
        }).then(() => {
          this.isBtnLoading = false;
          this.toast.isVisible = true;
          this.toast.type = 'success';
          this.toast.text = this.$t('pages.requestPassword.success.toastText');
          this.isEmailSent = true;

          this.$refs.emailInput.localInputValue = undefined;
          this.emailInput.value = undefined;
        }).catch((error) => {
          this.isBtnLoading = false;
          this.toast.isVisible = true;
          this.toast.type = 'error';
          this.toast.text = error.data.message;

          this.scrollToToast('requestPasswordReset');
        });
      }
    },
  },
};
</script>

<style lang='scss' scoped>
$contentMaxWidth: 670px;
$logoWidth: 137px;
$logoHeight: 45px;
$inputWidth: 210px;

#request-passwword-reset {
  padding: 130px 0 230px;
}

.error-toast {
  margin: 0 50px 20px;

  @media screen and (max-width: $breakpointDownSm) {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.content-wrapper {
  border-radius: $globalBorderRadius;
  box-shadow: $lightDropdownShadow;
  max-width: $contentMaxWidth;
  margin: 0 auto;
  background-color: $white;
  padding: 30px 40px 40px;
  text-align: center;

  @media screen and (max-width: $breakpointDownXs) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.logo {
  display: block;
  width: $logoWidth;
  height: $logoHeight;
  mask-image: url('/static/images/logos/eger-logo_colorfull.png');
  mask-repeat: no-repeat;
  mask-size: contain;
  background-color: $primaryBlue;
  margin: 0 auto 28px;
}

p {
  font-size: 12px;
  line-height: 1.4;
  margin: 0 0 15px;

  @media screen and (max-width: $breakpointDownXs) {
    font-size: 13px;
  }
}

.title {
  @include titleMixin();
  margin: 0 0 25px;
}

.form-wrapper {
  width: $inputWidth;
  margin: 35px auto 0;

  .base-input {
    &::v-deep input {
      text-align: center;
    }
  }

  @media screen and (max-width: $breakpointDownSm) {
    width: 70%;
  }

  button {
    margin: 20px 0 0;
    display: block;
    width: 100%;

    @media screen and (max-width: $breakpointDownSm) {
      height: 45px;
    }
  }
}
</style>
